import React from 'react';
import { Box, Text } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';
import wallTexturePath from '../marble.jpg';

function ObjectBasement({
  position,
  scale,
  color,
  text = "", // Default text if none provided
  fontSize,
  textColor,
  anchorX,
  anchorY,
  rotation,
  textPosition = [0, 0, 0], // Default value for textPosition
}) {
  const texture = useLoader(TextureLoader, wallTexturePath);

  // Split the text into paragraphs based on double line breaks
  const paragraphs = typeof text === 'string' ? text.split('\n\n') : [];

  return (
    <>
      {/* Wall Box */}
      <Box position={position} scale={scale} castShadow receiveShadow>
        <meshStandardMaterial attach="material" color={color} map={texture} />
      </Box>

      {/* Dynamic Text on the Wall, aligned with the wall */}
      {paragraphs.map((paragraph, index) => {
        const lines = paragraph.split('\n'); // Split each paragraph into lines
        return lines.map((line, lineIndex) => (
          <Text
            key={`${index}-${lineIndex}`} // Unique key for each line
            position={[textPosition[0], textPosition[1] - index * 0.5 - lineIndex * 0.3, textPosition[2]]} // Adjust vertical position for each line
            fontSize={fontSize}
            color={textColor}
            anchorX={anchorX}
            anchorY={anchorY}
            rotation={rotation}  // Apply the rotation passed as a prop
            backfaceCulling
          >
            {line}
          </Text>
        ));
      })}
    </>
  );
}

export default ObjectBasement;
