import React from 'react';
import { Box } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';
import Wall5TexturePath from '../muri5.jpg';

function Wall5({ position, scale, color }) {
  const texture = useLoader(TextureLoader, Wall5TexturePath);

  return (
    <Box position={position} scale={scale} castShadow receiveShadow>
      
      <meshStandardMaterial attach="material-0" color="#b3dae1"/>
            {/* Other sides */}
      <meshStandardMaterial attach="material-1" color={color} map={texture} /> {/* Right */}
      <meshStandardMaterial attach="material-2" color="#b3dae1" /> {/* Left */}
      <meshStandardMaterial attach="material-3" color="#b3dae1" /> {/* Back */}
      <meshStandardMaterial attach="material-4" color="#b3dae1" /> {/* Top */}
      <meshStandardMaterial attach="material-5" color="#b3dae1" /> {/* Bottom */}
    </Box>
  );
}

export default Wall5;

