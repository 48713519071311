import React from 'react';
import { Box } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';
import wall15TexturePath from '../wall15.jpg';
import commonTexturePath from '../texture.png';
import verticalPath from '../vertical1.jpg';

function Wall15({ position, scale, color }) {
  const texture = useLoader(TextureLoader, wall15TexturePath);
  const commonTexture = useLoader(TextureLoader, commonTexturePath);
  const verticalTexture = useLoader(TextureLoader, verticalPath);

  return (
    <Box position={position} scale={scale} castShadow receiveShadow>
      
      <meshStandardMaterial attach="material-0" color="#efefef" map={texture}/>
            {/* Other sides */}
      <meshStandardMaterial attach="material-1" color="#FFF" map={commonTexture} /> {/* Right */}
      <meshStandardMaterial attach="material-2" color="#2e5767" map={commonTexture}/> {/* Left */}
      <meshStandardMaterial attach="material-3" color="#2e5767" map={commonTexture} /> {/* Back */}
      <meshStandardMaterial attach="material-4" color="#2e5767" map={commonTexture} /> {/* Top */}
      <meshStandardMaterial attach="material-5" color="" map={commonTexture} /> {/* Bottom */}
    </Box>
  );
}

export default Wall15;

