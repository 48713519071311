import React, { useRef, useEffect } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { PointerLockControls, PerspectiveCamera, useGLTF } from '@react-three/drei';
import * as THREE from 'three';




const MovingCamera = () => 
  {
  const { camera, scene } = useThree();

  const speed = 0.5;
  const movement = useRef({ forward: false, backward: false, left: false, right: false });

  // Bounding boxes for objects (adjust these based on your objects' positions and scales)
  const ballBoundingBox = new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(2, 1, 0), new THREE.Vector3(2, 2, 2));
  const wallBoundingBox = new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(0, 1, -5), new THREE.Vector3(10, 2, 0.1));

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case 'ArrowUp':
        case 'w':
          movement.current.forward = true;
          break;
        case 'ArrowDown':
        case 's':
          movement.current.backward = true;
          break;
        case 'ArrowLeft':
        case 'd':
          movement.current.left = true;
          break;
        case 'ArrowRight':
        case 'a':
          movement.current.right = true;
          break;
        default:
          break;
      }
    };

    const handleKeyUp = (event) => {
      switch (event.key) {
        case 'ArrowUp':
        case 'w':
          movement.current.forward = false;
          break;
        case 'ArrowDown':
        case 's':
          movement.current.backward = false;
          break;
        case 'ArrowLeft':
        case 'd':
          movement.current.left = false;
          break;
        case 'ArrowRight':
        case 'a':
          movement.current.right = false;
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  // Function to detect collisions with bounding boxes
  const detectCollisions = (newPosition) => {
    const cameraBoundingBox = new THREE.Box3().setFromCenterAndSize(newPosition, new THREE.Vector3(1, 1, 1)); // Adjust camera bounding box size as needed

    // Check if camera bounding box intersects with ball or wall
    if (cameraBoundingBox.intersectsBox(ballBoundingBox) || cameraBoundingBox.intersectsBox(wallBoundingBox)) {
      return true; // Collision detected
    }
    return false;
  };

  useFrame(() => {
    const direction = new THREE.Vector3();
    const right = new THREE.Vector3();

    camera.getWorldDirection(direction);
    right.crossVectors(camera.up, direction).normalize();
    direction.set(0, 0, 0);

    if (movement.current.forward) direction.add(camera.getWorldDirection(new THREE.Vector3()).multiplyScalar(speed));
    if (movement.current.backward) direction.add(camera.getWorldDirection(new THREE.Vector3()).multiplyScalar(-speed));
    if (movement.current.left) direction.add(right.multiplyScalar(-speed));
    if (movement.current.right) direction.add(right.multiplyScalar(speed));

    const newPosition = camera.position.clone().add(direction);

    // Check for collisions before moving the camera
    if (!detectCollisions(newPosition)) {
      camera.position.copy(newPosition); // Update camera position only if no collision is detected
    }
  });

  return <PerspectiveCamera makeDefault position={[0, 1, 5]} />;
};

// Component to load the GLB model
const MardouMuseumModel = () => {
  const gltf = useGLTF(`${process.env.PUBLIC_URL}/the_mardou_museum.glb` ); // Load the model
  return <primitive object={gltf.scene} scale={1} position={[-50, 10, 450]} castShadow receiveShadow />;
};
const Apollonia = () => {
  const gltf = useGLTF(`${process.env.PUBLIC_URL}/koke_bebi_1.glb` ); // Load the model
  return <primitive object={gltf.scene} scale={1} position={[0, 0, 0]} castShadow receiveShadow />;
};


const TestPage = () => {
  return (
    <Canvas
      style={{ width: '100vw', height: '100vh' }}
      shadows
      camera={{ position: [0, 0, 0], fov: 65 }}
    >
      <MovingCamera />
      <PointerLockControls />

      {/* Simple Wall */}
      <mesh position={[0, 3, -5]} scale={[10, 5, 0.1]} castShadow receiveShadow>
        <boxGeometry />
        <meshStandardMaterial color="gray" />
      </mesh>

      <mesh position={[0, 3, 5]} scale={[10, 15, 0.1]} castShadow receiveShadow>
        <boxGeometry />
        <meshStandardMaterial color="gray" />
      </mesh>


      {/* Floor */}
      <mesh position={[0, 0, 0]} scale={[10, 0.1, 10]} castShadow receiveShadow>
        <boxGeometry />
        <meshStandardMaterial color="lightblue" />
      </mesh>

      {/* Sphere */}
      <mesh position={[2, 1, 0]} scale={[1, 1, 1]} castShadow receiveShadow>
        <sphereGeometry />
        <meshStandardMaterial color="red" />
      </mesh>

      {/* Ambient Light */}
      <ambientLight intensity={1} />

      {/* Directional Light */}
      <directionalLight 
        position={[10, 10, 5]} 
        intensity={1} 
        castShadow 
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
        shadow-camera-far={50}
        shadow-camera-left={-10}
        shadow-camera-right={10}
        shadow-camera-top={10}
        shadow-camera-bottom={-10}
      />

      {/* Additional Spotlight for Emphasis */}
      <spotLight
  position={[0, 5, 5]}
  angle={0.3}
  intensity={1}
  castShadow
  penumbra={1}
  shadow-mapSize-width={1024}
  shadow-mapSize-height={1024}
  shadow-bias={-0.0001}  // Reduce shadow artifacts
  shadow-normalBias={0.05}  // Adjust for better shadow quality
/>

      {/* The Mardou Museum Model */}
      <MardouMuseumModel />
      <Apollonia />

    </Canvas>
  );
};

export default TestPage;
