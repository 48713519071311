import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Canvas, useFrame } from '@react-three/fiber';
import { PerspectiveCamera, OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

import Loading from './components/Loading';
import MainSection from './components/MainSection';
import Gallery from './components/Gallery';
import Museum from './components/Museum';
import Navbar from './components/Navbar';
import TestPage from './components/TestPage';
import Aboutus from './components/Aboutus';
import '@fortawesome/fontawesome-free/css/all.min.css';


import './App.css';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an async operation like data fetching
    const loadAppData = async () => {
      try {
        // Replace this with your actual data fetching or initialization logic
        await new Promise(resolve => setTimeout(resolve, 2000)); // Simulate loading time
        setIsLoading(false);
      } catch (error) {
        console.error('Error loading app data:', error);
        setIsLoading(false);
      }
    };

    loadAppData();
  }, []);

  return (
    <Router>
      <Navbar /> {/* Navbar rendered once outside Routes */}
      {isLoading ? (
        <Loading /> // Show loading component while data is being fetched
      ) : (
        <Routes>
          <Route path="/" element={<MainSection />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/museum" element={<Museum />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/test" element={<TestPage />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;
