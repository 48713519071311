import React from 'react';
import { Box } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';
import wall13TexturePath from '../wall13.jpg';
import commonTexturePath from '../texture.png';
function Wall13({ position, scale}) {
  const texture = useLoader(TextureLoader, wall13TexturePath);
  const commonTexture = useLoader(TextureLoader, commonTexturePath);
  return (
    <Box position={position} scale={scale}>     
      <meshStandardMaterial attach="material-0" color="#999" map={commonTexture}/>
      <meshStandardMaterial attach="material-1" color="#e3e3e3" map={texture} />
      <meshStandardMaterial attach="material-2" />
      <meshStandardMaterial attach="material-3" />
      <meshStandardMaterial attach="material-4" color="#999" />
      <meshStandardMaterial attach="material-5"  />
    </Box>
  );
}
export default Wall13;