import React from 'react';
import { Box } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';
import ceilingTexturePath from '../beton.jpg';

function Ceiling({ position, scale, color }) {
  const texture = useLoader(TextureLoader, ceilingTexturePath);

  return (
    <Box position={position} scale={scale} castShadow receiveShadow>
      <meshStandardMaterial attach="material" color={color} map={texture} />
    </Box>
  );
}

export default Ceiling;