import React from 'react';
import { Box } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';
import wall8TexturePath from '../wall8.jpg';
import commonTexturePath from '../texture.png';
import verticalPath from '../vertical1.jpg';

function Wall8({ position, scale, color }) {
  const texture = useLoader(TextureLoader, wall8TexturePath);
  const commonTexture = useLoader(TextureLoader, commonTexturePath);
  const verticalTexture = useLoader(TextureLoader, verticalPath);

  return (
    <Box position={position} scale={scale} castShadow receiveShadow>
      
      <meshStandardMaterial attach="material-0" color="#2e5767" map={commonTexture}/>
            {/* Other sides */}
      <meshStandardMaterial attach="material-1" color="#FFF" map={texture} /> {/* Right */}
      <meshStandardMaterial attach="material-2" color="#2e5767" /> {/* Left */}
      <meshStandardMaterial attach="material-3" color="#2e5767" /> {/* Back */}
      <meshStandardMaterial attach="material-4" color="#2e5767" /> {/* Top */}
      <meshStandardMaterial attach="material-5" color="" map={texture} /> {/* Bottom */}
    </Box>
  );
}

export default Wall8;

