
import React from 'react';
import Navbar from './Navbar';
import './Aboutus.css';

function Aboutus() {
  return (
    <>
<div className="container-fluid p-0">
  <div className="col-12">
    <img src="ABOUT_US_BANNER.png" className="w-100" />
  </div>
</div>

      <div className="container mt-5">


        <h1 className="big-title">
        DISCOVER ALBANIA'S HERITAGE <br />
        FROM EVERY ANGLE</h1>




        <div className="row mt-5" >
          
          

          <div className="col-md-6 col-sm-12 col-xs-12">

 
         
        
                <p className="about-text">Explore the Albanian past like never before with EveryAngle, 
                  the 3D virtual museum that combines cutting-edge technology and rich cultural heritage. 
                  The museum features an immersive collection of 50 archaeological artifacts and 5 archaeological
                   parks from Albania. Each object can be explored in stunning 360-degree detail, offering a unique,
                    interactive experience that brings Albania's rich history to life from every perspective.
                    
                    Through advanced 3D Reality Capturing, EveryAngle transports you to a virtual world where you can experience
 Albania’s heritage as if you were physically present, offering an unparalleled journey through time. 
 Immerse yourself in the stories behind our most cherished artifacts, monuments, and landscapes.
</p>

</div>

<div className="col-md-6 col-sm-12 col-xs-12">
   <p className="about-text">
EveryAngle’s mission is to bridge the gap between past and present by making Albania's archaeological 
and historical wonders fully accessible, no matter where you are. Our virtual platform educates, inspires, 
and engages both locals and international audiences, preserving and showcasing cultural heritage in an 
interactive way that transcends geography and time.
</p>
</div>

</div></div>


<div className="container mt-5">

          <div className="col-md-12 col-sm-12 col-xs-12">

          <div className="ratio ratio-16x9">


<video width="2000" height="1080" controls>
  <source src="video.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>


</div>


   

</div>
<div className="container mt-5 sponsors-container"> 

          <div className="row ">

    
          <div className="col-md-12 col-sm-12 col-xs-12 ">
          <h2 className="secondary-title secondary-title-font">
          Every Angle could not have been possible
          without the help of our partners:</h2>
          </div>
          

          <div className="col-md-2 col-sm-4 col-xs-6 image-container ">
        
              <img src="TURIZMI.png" />


          </div>  
          <div className="col-md-2 col-sm-4 col-xs-6 image-container">

              <img src="MUZEU.png" />


          </div>  
          <div className="col-md-2 col-sm-4 col-xs-6 image-container">

              <img src="MUZEU_ARK.png" />


          </div>            
    
          <div className="col-md-2 col-sm-4 col-xs-6 image-container">

              <img src="APOLLONI.png" />

          </div>              
          <div className="col-md-2 col-sm-4 col-xs-6 image-container">

              <img src="BUTRINT.png"  />
   

          </div>
          <div className="col-md-2 col-sm-4 col-xs-6 image-container">
        
              <img src="BYLIS.png" class="img-responsive" />  
           
          </div>          
 
          </div>          
          
          </div>






        <div className="row mt-5">
          {/* Column 1 */}
          <div className="col-md-6 col-sm-6 col-xs-6">
          <a href="museum"><div className="card">
              <img src="THUMB_1.png" />
              <div className="card-body">
                <h2 className="secondary-title-font">Discover the museum</h2>
              </div>
            </div></a>
          </div>

          {/* Column 2 */}
          <div className="col-md-6 col-sm-6 col-xs-6">
           <a href="gallery"><div className="card">
              <img src="THUMB_2.png" />
              <div className="card-body">
                <h2 className="secondary-title-font">Discover the museum gallery</h2>
              </div>
            </div></a>
          </div>




        </div>
      </div>


      <div className="container mt-5"> 

<div className="row footer">

  </div></div>      
    </>
  );
}

export default Aboutus;



