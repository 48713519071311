import React, { useEffect, useRef } from 'react';
import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import * as THREE from 'three';

const SceneObject = ({ url, position, scale, castShadow = true, receiveShadow = true, onClick }) => {
  const gltf = useLoader(GLTFLoader, url, (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.7/');
    dracoLoader.setDecoderConfig({ type: 'js' });
    loader.setDRACOLoader(dracoLoader);
  });

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.traverse((child) => {
        if (child.isMesh) {
          // Apply darker material properties
          if (!(child.material instanceof THREE.MeshStandardMaterial)) {
            child.material = new THREE.MeshStandardMaterial({
              map: child.material.map, // Preserve texture map
              color: child.material.color, // Preserve color
            });
          }

          // Adjust material to make the object darker
          child.material.color = new THREE.Color(0xefefef); // Darker base color a5a8ac
          child.material.metalness = 0.2; // Lower reflectivity for less brightness
          child.material.roughness = 0.9; // Higher roughness makes surface more diffuse
          child.material.emissive = new THREE.Color(0x999); // No emissive light
          child.material.emissiveIntensity = 0.0; // Ensure no emissive glow

          child.castShadow = castShadow;
          child.receiveShadow = receiveShadow;
        }
      });
    }
  }, [castShadow, receiveShadow]);

  return (
    <group 
      ref={ref} 
      position={position} 
      scale={scale} 
      castShadow 
      receiveShadow 
      onClick={onClick}  // Attach the onClick event here
    >
      <primitive object={gltf.scene} />
    </group>
  );
};

export default SceneObject;
