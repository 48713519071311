// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  return (
    <nav className="navbar">
      <div className="navbar-logo"><Link to="/"><img src="LOGO-EAA.png" width="250px"/></Link></div>
      <ul className="navbar-menu">

      <li className="navbar-item">
          <Link to="/">Home</Link>
        </li>

        <li className="navbar-item">
          <Link to="/gallery">Gallery</Link>
        </li>
        <li className="navbar-item">
          <Link to="/museum">Museum</Link>
        </li>
        {/*<li className="navbar-item">
          <Link to="/test">TestPage</Link>
        </li> */}
        <li className="navbar-item">
          <Link to="/aboutus">About us</Link>
        </li> 
      </ul>
    </nav>
  );
}

export default Navbar;
