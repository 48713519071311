// BoundingBox.js
import React from 'react';
import { Box } from '@react-three/drei';
import PropTypes from 'prop-types';

// The BoundingBox component renders a box that can be used for collision detection or as a visual guide
const BoundingBox = ({ position, dimensions, color = 'blue', wireframe = true }) => {
  return (
    <Box args={dimensions} position={position}>
      <meshBasicMaterial color={color} wireframe={wireframe} />
    </Box>
  );
};

// Define the prop types to ensure correct usage
BoundingBox.propTypes = {
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
  dimensions: PropTypes.arrayOf(PropTypes.number).isRequired,
  color: PropTypes.string,
  wireframe: PropTypes.bool,
};

// Define default props
BoundingBox.defaultProps = {
  color: 'blue',
  wireframe: true,
};

export default BoundingBox;
