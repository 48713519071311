import React from 'react';
import './MainSection.css';

function MainSection() {
  return (
    <>
      {/* Main Title Row */}
      <div className='homepage'>
        <div className="container-fluid no-padding">
          <div className="row">
            <div className="col text-center">
              <h1 className='main-title'><strong>ALBANIAN CULTURAL TOURISM</strong></h1>
            </div>
          </div>
        </div>

        {/* Row with Three Equal Columns */}
        <div className="container-fluid no-padding">
          <div className="row text-center">
            <div className="col-md-4">
              <div className="main-text">
                <img src="everyangle.png" className='img-fluid' alt="Every Angle" />
              </div>
            </div>
            <div className="col-md-4">
              <div className="">
                <img src="homedoor.png" className='img-fluid' alt="Home Door" /><br />
                <a href='museum'>
                <div className="arrow"></div> <br />           
                <span className='main-subtitle'>ENTER</span> 
              </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className='description'>
                <h1 className='description-title'>VIRTUAL <br />MUSEUM</h1>
                <p className='small-center'>Welcome to Every Angle Archaeology, where history meets innovation. Prepare to explore Albania’s most fascinating archaeological sites in 360! Your journey through time is about to begin!</p>
              </div>
            </div>
          </div>
        </div>

        {/* Subtitle Row */}
        <div className="container-fluid no-padding">
          <div className="row text-center">
            <div className="col text-center">
              <h2 className='main-subtitle'>ENJOY YOUR 3D VIRTUAL TOUR TO DISCOVER ALBANIA'S CULTURAL ATTRACTIONS</h2>           
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainSection;
