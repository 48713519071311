import React from 'react';
import './Loading.css';

const Loading = () => {
  return (
    <div className="loading">
      <div className="loader">
        <div className="loader-text">Loading content to serve...</div>
        <div className="loader-icon">
          <div className="loader-archaeology"></div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
