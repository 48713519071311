import React from 'react';
import { Box } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';
import wall10TexturePath from '../wall10.jpg';
import commonTexturePath from '../texture.png';
import verticalPath from '../vertical1.jpg';

function Wall10({ position, scale, color }) {
  const texture = useLoader(TextureLoader, wall10TexturePath);
  const commonTexture = useLoader(TextureLoader, commonTexturePath);
  const verticalTexture = useLoader(TextureLoader, verticalPath);

  return (
    <Box position={position} scale={scale} castShadow receiveShadow>
      
      <meshStandardMaterial attach="material-0" color="red" map={texture}/>
            {/* Other sides */}
      <meshStandardMaterial attach="material-1" color="#2e5767" map={texture} /> {/* Right */}
      <meshStandardMaterial attach="material-2" color="#2e5767" map={texture}/> {/* Left */}
      <meshStandardMaterial attach="material-3" color="#2e5767" map={texture}/> {/* Back */}
      <meshStandardMaterial attach="material-4" color="#fff" map={texture}/> {/* Top */}
      <meshStandardMaterial attach="material-5" color="#2e5767" map={texture} /> {/* Bottom */}
    </Box>
  );
}

export default Wall10;

