import React from 'react';
import { Box } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';
import floorTexturePath from '../dysheme.png';

function Floor({ position, scale, color }) {
  const texture = useLoader(TextureLoader, floorTexturePath);

  return (
    <Box position={position} scale={scale} castShadow receiveShadow>
      <meshStandardMaterial attach="material" color={color} map={texture} />
    </Box>
  );
}

export default Floor;