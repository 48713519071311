import React, { useMemo } from 'react';
import { Box } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import * as THREE from 'three';  // Import THREE namespace
import Wall3TexturePath from '../muri3.jpg';

function Wall3({ position, scale }) {
  // Load the texture only once using useMemo
  const texture = useLoader(THREE.TextureLoader, Wall3TexturePath);

  // Memoize the material to prevent re-creation on each render
  const materials = useMemo(() => {
    return [
      new THREE.MeshStandardMaterial({ color: "#d3d3d3", map: texture }),  // Material for one side with texture
      new THREE.MeshStandardMaterial({ color: "#1c5e6c" })  // Material for the other sides
    ];
  }, [texture]);

  return (
    <Box position={position} scale={scale} material={materials} />
  );
}

export default Wall3;
