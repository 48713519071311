import React from 'react';
import { Box } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';
import Wall5TexturePath from '../wall5.jpg';
import Wall5TexturePicture from '../Muri 5.jpg';


function Wall5({ position, scale}) {
  const texture = useLoader(TextureLoader, Wall5TexturePath);
  const picture = useLoader(TextureLoader, Wall5TexturePicture);
  

  return (
    <Box position={position} scale={scale} >      
      <meshStandardMaterial attach="material-1" color="#d3d3d3" map={picture} />
      <meshStandardMaterial attach="material-0" color="#FFF" map={texture}  />      
      <meshStandardMaterial attach="material-2" color="#b3dae1"  />
      <meshStandardMaterial attach="material-3" color="#b3dae1"  />
      <meshStandardMaterial attach="material-4" color="#b3dae1" />
      <meshStandardMaterial attach="material-5" color="#555"   />
    </Box>
  );
}

export default Wall5;

